@import "../variables";
/* ###### List-group ###### */


.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  color: #3c4858;
}

.list-group-item-action {
  width: 100%;
  color: #3c4858;
  text-align: inherit;

  &:hover, &:focus {
    color: #3c4858;
    text-decoration: none;
    background-color: #f1f2f9;
  }

  &:active {
    color: #8f9cc0;
    background-color: $background;
  }

  h5 {
    font-size: 16px;
  }

  p {
    font-size: 14px;
  }
}

.list-group-item {
  position: relative;
  display: block;
  padding: 16px 15px;
  margin-bottom: -1px;
  background-color: $white;
  border: 1px solid #e8e8f7;

  &.disabled, &:disabled {
    color: #a8afc7;
    pointer-events: none;
    background-color: $white;
  }
}

.list-group-horizontal {
  flex-direction: row;

  .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;

    &:first-child {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      border-top-right-radius: 0;
    }

    &:last-child {
      margin-right: 0;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      border-bottom-left-radius: 0;
    }
  }
}

.listgroup-example2 ul ul {
  list-style-type: circle;
  margin: 10px 0 0 0;
}

.listgroup-example ul, .listgroup-example2 ul {
  list-style-type: none;
}

.listgroup-example ul li, .listgroup-example2 ul li {
  padding: 5px 5px;
}

.listgroup-example .list-group.checked .list-group-item {
  border: 0;
}

.listorder {
  position: relative;
  margin-bottom: 0;
  border: 0;
  list-style-type: decimal;
  list-style-position: inside;
  padding: 7px 11px !important;
  margin-right: 0;
}

.icons-list-wrap {
  .icons-list {
    list-style: none;
    margin: 0 -1px -1px 0;
    padding: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: center;

    &-item {
      text-align: center;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: center;
      justify-content: center;
      height: 165px;
      width: 165px;
      background: $gray-100;
      margin: 5px 5px;
      border-radius: 6px;
      border: 1px solid #e4e4f9;
    }

    .selected-option{
      border: 2px solid $white;
      background-color: $primary;
      transform: translateY(-1px);
      transition: 1s ease-in-out;
      box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.1),
      0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12) !important;
    }
  }
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;

    .list-group-item {
      margin-right: -1px;
      margin-bottom: 0;

      &:first-child {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        border-top-right-radius: 0;
      }

      &:last-child {
        margin-right: 0;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 0;
      }
    }
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;

    .list-group-item {
      margin-right: -1px;
      margin-bottom: 0;

      &:first-child {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        border-top-right-radius: 0;
      }

      &:last-child {
        margin-right: 0;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 0;
      }
    }
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;

    .list-group-item {
      margin-right: -1px;
      margin-bottom: 0;

      &:first-child {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        border-top-right-radius: 0;
      }

      &:last-child {
        margin-right: 0;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 0;
      }
    }
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;

    .list-group-item {
      margin-right: -1px;
      margin-bottom: 0;

      &:first-child {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        border-top-right-radius: 0;
      }

      &:last-child {
        margin-right: 0;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 0;
      }
    }
  }
}

.list-group-flush {
  .list-group-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0;

    &:last-child {
      margin-bottom: -1px;
    }
  }

  &:first-child .list-group-item:first-child {
    border-top: 0;
  }

  &:last-child .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom: 0;
  }
}

.list-group-item-primary {
  color: #1b3585;
  background-color: #c6d4ff;

  &.list-group-item-action {
    &:hover, &:focus {
      color: #1b3585;
      background-color: #adc1ff;
    }

    &.active {
      color: $white;
      background-color: #1b3585;
      border-color: #1b3585;
    }
  }
}

.list-group-item-secondary {
  color: $secondary;
  background-color: #f9e5db;

  &.list-group-item-action {
    &:hover, &:focus {
      color: #3f4654;
      background-color: #cacfdb;
    }

    &.active {
      color: $white;
      background-color: #3f4654;
      border-color: #3f4654;
    }
  }
}

.list-group-item-success {
  color: #1f5c01;
  background-color: #c8e9b8;

  &.list-group-item-action {
    &:hover, &:focus {
      color: #1f5c01;
      background-color: #b9e3a5;
    }

    &.active {
      color: $white;
      background-color: #1f5c01;
      border-color: #1f5c01;
    }
  }
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;

  &.list-group-item-action {
    &:hover, &:focus {
      color: #0c5460;
      background-color: #abdde5;
    }

    &.active {
      color: $white;
      background-color: #0c5460;
      border-color: #0c5460;
    }
  }
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;

  &.list-group-item-action {
    &:hover, &:focus {
      color: #856404;
      background-color: #ffe8a1;
    }

    &.active {
      color: $white;
      background-color: #856404;
      border-color: #856404;
    }
  }
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;

  &.list-group-item-action {
    &:hover, &:focus {
      color: #721c24;
      background-color: #f1b0b7;
    }

    &.active {
      color: $white;
      background-color: #721c24;
      border-color: #721c24;
    }
  }
}

.list-group-item-light {
  color: #8f9cc0;
  background-color: #f1f2f9;

  &.list-group-item-action {
    &:hover, &:focus {
      color: #8f9cc0;
      background-color: $background;
    }

    &.active {
      color: $white;
      background-color: #7f7f81;
      border-color: #7f7f81;
    }
  }
}

.list-group-item-dark {
  color: #1f2533;
  background-color: #c8ccd3;

  &.list-group-item-action {
    &:hover, &:focus {
      color: #1f2533;
      background-color: #babfc8;
    }

    &.active {
      color: $white;
      background-color: #1f2533;
      border-color: #1f2533;
    }
  }
}

.list-group-item.active {
  color: $white;
  background-color: $primary;
  border-color: $primary;
}

/* ###### List-group ###### */