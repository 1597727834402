@import "variables";

.eye-right {
  font-size: 15px;
  color: #828996;
  display: inline-block;
  position: absolute;
  z-index: 1;
  right: 29px;
  bottom: 8px;
  cursor: pointer;

  &.extended {
    z-index: 5;
    right: 40px;
  }
}

.delete-right {
  font-size: 15px;
  color: #828996;
  display: inline-block;
  position: absolute;
  z-index: 1;
  right: 15px;
  bottom: 8px;
  cursor: pointer;

  &.extended {
    z-index: 5;
    right: 40px;
  }
}

.pointer-event {
  cursor: pointer;
}

.text-right {
  text-align: end !important;
}

.bg-custom {
  background: $white url("../images/svg/mural.svg") no-repeat center;
  background-size: cover;
}

.jumps-prevent {
  margin-top: 75px;

  @media (max-width: 480px) {
    margin-top: 0;
  }
}

.mobile-main-header .dropdown.main-profile-menu i,
.mobile-main-header .main-header-notification i {
  font-size: 25px;
}

.collapse.navbar-collapse .d-flex.flex-end {
  justify-content: flex-end !important;
}

.main-profile-menu {
  padding-left: 15px;
}

.navbar-toggler:focus {
  box-shadow: none !important;
}

.hover-underline {
  &:hover {
    text-decoration: underline;
  }
}

.google-button {
  width: 28px;
  height: 25px;
  padding-right: 10px
}

.table> :not(:first-child) {
  border-top: 0.2px solid black !important;
}

.loader-modal {
  padding: 20px;
  position: absolute;
  top: 200px;
  width: 100%;
  right: 2%;
  height: auto;
  z-index: 1;
}

.openModal:after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
  content: "";
  z-index: 999;
}

.remove-ml-media-body {
  margin-left: 0 !important;
}


.react-datepicker {
  font-family: Helvetica, "sans-serif";
  font-size: 1rem !important;
}

.css-b62m3t-container,
.css-13cymwt-control,
.css-qbdosj-Input,
.css-1fdsijx-ValueContainer,
.css-1dimb5e-singleValue,
.css-1f43avz-a11yText-A11yText,
.css-1hb7zxy-IndicatorsContainer,
.css-1jqq78o-placeholder {
  border-color: #e8e8f7 !important;
  color: #212529;


  &:focus {
    border-color: #e8e8f7 !important;
  }
}

.css-b62m3t-container.is-invalid {
  .css-13cymwt-control {
    border: 1px solid #f16d75 !important;
  }

}

.react-datepicker-wrapper {
  width: 100%;
  border-radius: 5px;
}

.react-datepicker__current-month,
.react-datepicker-year-header,
.react-datepicker-time__header {
  color: $white !important;
}

.react-datepicker__day-name {
  color: #e8e8f7 !important;
}

.react-datepicker__header {
  background-color: $primary;
  color: $white !important;
  border-bottom: none !important;
}

.react-datepicker__day--selected {
  border-radius: 0 !important;
}

.react-datepicker__input-container {
  border: 1px solid #e8e8f7;
  border-radius: 5px;
  height: inherit;

  .is-invalid {
    border: 1px solid #f16d75 !important;
    border-radius: 4px;
  }

  input {
    width: 100%;
    padding: 6px;
    border: none !important;

    &:disabled,
    &:focus,
    &:active {
      border: none !important;
    }
  }
}

.react-datepicker__navigation {
  top: 15px !important;
}

.react-datepicker__navigation--previous {
  background: url(../images/svg/nav-left-arrow-white.svg) no-repeat;
  width: 40px;
  height: 30px;
  margin: 0 0 0 20px;
}

.react-datepicker__navigation--next {
  background: url(../images/svg/nav-right-arrow-white.svg) no-repeat;
  width: 40px;
  height: 30px;
  margin: -2px 0 0 20px;
}

.react-datepicker__navigation-icon {
  &::before {
    border-style: none;
  }
}

.media-body .tx-medium span.badge.bg-pill {
  display: inline;
  font-size: 10px;
}

.box {
  display: inline-block;
  padding: 10px;
  box-sizing: border-box;
}

.img-preview {
  overflow: hidden;
}

.alert-note {
  font-size: 12px;
  color: #ff0000;
}

.vertical-line {
  display: inline-block;
  border-left: 1px solid #ccc;
  margin: 0 10px;
  height: 75vh;
}

#custom-brand {
  right: 0;
  bottom: 0;
  width: 150px;
  height: 150px;
  display: block;
  margin: auto;
  background-color: lighten(rgba(143, 143, 183, 0.42), 15%);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
