@import "../variables";

.tree, .tree ul {
  margin: 0;
  padding: 0;
  list-style: none
}

.tree ul {
  margin-left: 1em;
  position: relative
}

.tree ul ul {
  margin-left: .5em
}

.tree ul:before {
  content: "";
  display: block;
  width: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -10px;
  border-left: 2px solid #d8e0f7;
}

.tree li {
  padding: 0.5em 0;
  color: #17171b;
  position: relative;
  border: 1px solid #e6eaf7;
  margin: 10px 0;
  border-radius: 6px;
  line-height: 30px;
}

.tree ul li:before {
  content: "";
  display: block;
  width: 10px;
  height: 0;
  border-top: 2px solid #d3dfea;
  margin-top: -1px;
  position: absolute;
  top: 22px;
  left: -10px;
}

.tree ul li:last-child:before {
  background: transparent;
  height: auto;
  top: 22px;
  bottom: 0;
}

.indicator {
  margin-right: 5px;
  color: #4b10a4;
}

.tree li a {
  text-decoration: none;
  color: #424e79;
  font-size: 15px;
}

.tree li button, .tree li button:active, .tree li button:focus {
  text-decoration: none;
  color: #29273c;
  border: none;
  background: transparent;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  outline: 0;
}

.tree li.branch {
  background: #f1f3fb;
}

.tree li.branch li {
  background: #fff;
}

.irs-min, .irs-max, .tree li i {
  color: #4456c3;
}

.tree li i {
  margin-right: 10px;
  font-weight: bold;
  color: #4456c3;
  font-size: 16px;
  line-height: 1;
  cursor: pointer;
}
