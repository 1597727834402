@import "../variables";


@media (max-width: 991.98px) {
  .main-content-body-show {
    .main-header-menu-icon {
      display: none;
    }

    .main-header-left .main-header-arrow {
      display: block;
    }

    .main-content-left-chat {
      display: none;
    }

    .main-content-body-chat {
      display: flex;
    }
  }
}

.main-content-left-chat {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.main-nav-line-chat {
  border-bottom: 1px solid #e8e8f7;
  flex-shrink: 0;
  flex-direction: row;
  padding: 19px;

  .nav-link {
    font-size: 13px;
    font-weight: 700;
    color: $dark;
    text-transform: uppercase;

    &:hover, &:focus, &:active {
      outline: none;
    }

    + .nav-link {
      margin-top: 0;
      margin-left: 30px;
    }
  }
}

.main-chat-contacts-wrapper {
  padding: 10px 20px;
  flex-shrink: 0;
  border-bottom: 1px solid #e8e8f7;
  overflow: hidden;

  .main-content-label, .card-table-two .card-title {
    margin-bottom: 15px;
    line-height: 1;
  }
}

@media (min-width: 992px) {
  .main-chat-contacts-wrapper {
    padding: 10px 15px 10px 0;
  }
}

.card-table-two .main-chat-contacts-wrapper .card-title, .main-chat-contacts-wrapper .card-dashboard-eight .card-title, .card-dashboard-eight .main-chat-contacts-wrapper .card-title {
  margin-bottom: 15px;
  line-height: 1;
}

.main-chat-contacts-wrapper {
  .lSSlideOuter, .lSSlideWrapper {
    overflow: visible;
  }
}

.main-chat-contacts {
  padding-left: 20px;
  display: flex;

  .lslide {
    display: flex;
    flex-direction: column;
    align-items: center;
    float: none;
  }

  small {
    font-size: 11px;
    width: 36px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-top: 3px;
    text-align: center;
  }
}

.main-chat-contacts-more {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  font-size: 12px;
  font-weight: 500;
  color: $white;
}

@media (min-width: 992px) {
  .main-chat-list {
    height: calc(100% - 134px);
    position: relative;
  }
}

.main-chat-list {
  .media {
    padding: 12px 20px;
    border: 1px solid transparent;
    position: relative;

    .main-img-user {
      flex-shrink: 0;
      top: 3px;
    }

    + .media {
      margin-top: -1px;
      border-top: 1px solid #e8e8f7;
    }

    &.new {
      background-color: $white;

      .main-img-user span {
        display: flex;
      }

      .media-contact-name span:first-child {
        font-weight: 500;
        color: #404c6d;
      }

      .media-body p {
        color: #8f9cc0;
      }
    }

    &:hover, &:focus {
      cursor: pointer;
      background-color: #f4f5fb;
      border-top-color: $background;
      border-bottom-color: $background;
    }

    &:hover:first-child, &:focus:first-child {
      border-top-color: transparent;
    }

    &:hover:last-child, &:focus:last-child {
      border-radius: 0 0 11px 11px;
    }

    &.selected {
      background-color: #f1f2f9;
      border-top-color: $background;
      border-bottom-color: $background;

      &:first-child {
        border-top-color: transparent;
      }

      &::after {
        display: block;
      }

      .media-contact-name span:first-child {
        color: #404c6d;
      }

      .media-body p {
        color: #8f9cc0;
      }
    }
  }

  .main-img-user span {
    position: absolute;
    top: 0;
    left: -2px;
    width: 14px;
    height: 14px;
    display: none;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    font-size: 9px;
    font-weight: 500;
    color: $white;
    background-color: #f16d75;
    box-shadow: 0 0 0 2px $white;
  }

  .media-body {
    margin-left: 15px;

    p {
      font-size: 13px;
      margin-bottom: 0;
      color: #a8afc7;
      line-height: 1.35;
    }
  }

  .media-contact-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2px;

    span {
      &:first-child {
        display: block;
        font-size: 14px;
        font-weight: 500;
        color: #404c6d;
      }

      &:last-child {
        display: block;
        font-size: 11px;
        color: #a8afc7;
      }
    }
  }
}

@media (min-width: 992px) {
  .main-chat-list .media {
    padding: 12px 15px;
  }
}

.main-content-body-chat {
  flex-direction: column;
}

@media (min-width: 992px) {
  .main-content-body-chat {
    display: flex;
  }
}

.main-chat-header {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px 12px;
  border-bottom: 1px solid #e8e8f7;

  .nav {
    margin-left: auto;
    align-items: center;
  }

  .nav-link {
    padding: 0;
    color: #a8afc7;
    font-size: 16px;
    display: none;

    &:first-child {
      display: block;
    }

    i {
      line-height: 0;
    }

    .typcn {
      line-height: .9;

      &::before {
        width: auto;
      }
    }

    + .nav-link {
      margin-left: 15px;
    }
  }
}

@media (min-width: 576px) {
  .main-chat-header .nav-link {
    display: block;

    &:first-child {
      display: none;
    }
  }
}

.main-chat-msg-name {
  margin-left: 15px;

  h6 {
    margin-bottom: 0;
    font-size: 15px;
    font-weight: 700;
    color: #8f9cc0;
  }

  small {
    font-size: 12px;
    color: #a8afc7;
  }
}

.main-chat-body {
  position: relative;

  .content-inner {
    padding: 20px;
  }

  .media {
    + .media {
      margin-top: 20px;
    }

    &.flex-row-reverse {
      .media-body {
        margin-left: 0;
        margin-right: 20px;
        align-items: flex-end;
      }

      .main-msg-wrapper {
        background-color: rgb(233, 233, 249);
        color: #1e1635;
        position: relative;

        &:before, &:after {
          right: auto;
          left: 100%;
          border-right-color: transparent;
          border-left-color: rgb(233, 233, 249);
        }

        &:after {
          border-width: 8px;
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .main-chat-body {
    padding-bottom: 0;
    height: calc(100% - 105px);
    overflow: hidden;
  }
}

@media (min-width: 576px) {
  .main-chat-body .media.flex-row-reverse .media-body {
    margin-left: 55px;
  }
}


.main-msg-wrapper {
  padding: 10px 15px;
  background-color: #f4f6ff;
  font-size: 13px;
  margin-bottom: 5px;
  display: inline-block;
  border-radius: 4px;
  position: relative;

  &:before, &:after {
    border-color: transparent #f4f6ff transparent transparent;
    border-image: none;
    border-style: solid;
    border-width: medium;
    content: " ";
    height: 0;
    pointer-events: none;
    position: absolute;
    right: 100%;
    top: 5%;
    width: 0;
  }


  &:after {
    border-width: 8px;
  }
}

.main-chat-body .media-body {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > div:last-child {
    font-size: 11px;
    color: #a8afc7;
    display: flex;
    align-items: center;
  }
}

@media (min-width: 576px) {
  .main-chat-body .media-body {
    margin-right: 55px;
  }
}

.main-chat-time {
  display: block;
  position: relative;
  text-align: center;
  margin: 20px 0;

  &:first-of-type {
    margin-top: 0;
  }

  span {
    font-size: 10px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    background: #e6f3f9;
    padding: 5px;
    border-radius: 4px;
  }
}

.main-chat-footer {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 20px;
  border-top: 1px solid #e8e8f7;
  background-color: $white;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  .nav {
    align-items: center;
  }

  .nav-link {
    padding: 0;
    color: #a8afc7;
    font-size: 16px;
    display: none;

    + .nav-link {
      margin-left: 10px;
    }
  }

  .form-control {
    flex: 1;
    margin: 0 10px;
    padding-left: 0;
    padding-right: 0;
    border-width: 0;

    &:hover, &:focus {
      box-shadow: none;
    }
  }
}

@media (min-width: 992px) {
  .main-chat-footer {
    position: relative;
    bottom: auto;
    left: auto;
    right: auto;
    border-radius: 0 0 5px 5px;
  }
}

@media (min-width: 576px) {
  .main-chat-footer .nav-link {
    display: block;
  }
}

@media (min-width: 576px) {
  .main-chat-footer .form-control {
    margin-left: 20px;
  }
}

@media (min-width: 768px) {
  .main-chat-footer .form-control {
    margin: 0 20px;
  }
}

.main-msg-send {
  font-size: 20px;
}

.main-content-title {
  color: #170c6b;
  font-weight: 500;
  font-size: 32px;
  text-indent: -1px;
  line-height: 1;
  position: relative;
  margin-bottom: 20px;
}

.main-content-left-contacts {
  .main-content-breadcrumb, .main-content-title {
    padding-left: 20px;
  }
}

@media (min-width: 992px) {
  .main-content-left-contacts {
    .main-content-breadcrumb, .main-content-title {
      padding-left: 0;
    }
  }

  .main-content-body-contacts {
    display: block;
    overflow-y: auto;
  }
}
.main-chat-body .media.flex-row-reverse .main-msg-wrapper2 {
    background-color: #e9e9f9;
    color: #1e1635;
    position: relative;
}
.main-msg-wrapper2 {
    padding: 10px 15px;
    background-color: #f4f6ff;
    font-size: 13px;
    margin-bottom: 5px;
    display: inline-block;
    border-radius: 4px;
    position: relative;
}


/* ###### Chat  ###### */
