$background: #f0f0ff;
$color:#1d212f;

/*Color variables*/
$primary:#2C3891;
$primary-hover: rgba(44, 56, 145, 0.95);
$secondary:#3dc2ff;
$success:#19b159;
$warning:#ff9b21;
$danger:#fd6074;
$info: #6c79d3;
$info-hover: rgba(115, 126, 201, 0.90);
$dark:#3b4863;
$white:#ffffff;
$black:#000000;

/*gray variables*/
$gray-100:#eaeafb;
$gray-200:#d0d0ec;
$gray-300:#babade;
$gray-400:#8f8fb7;
$gray-500:#717196;
$gray-600:#4f4f71;
$gray-700:#3c3c5d;
$gray-800:#262644;
$gray-900:#1b1b33;

/*white variables*/
$white-1:rgba(255, 255, 255, 0.1);
$white-2:rgba(255, 255, 255, 0.2);
$white-3:rgba(255, 255, 255, 0.3);
$white-4 :rgba(255, 255, 255, 0.4);
$white-5 :rgba(255, 255, 255, 0.5);
$white-6 :rgba(255, 255, 255, 0.6);
$white-7 :rgba(255, 255, 255, 0.7);
$white-8 :rgba(255, 255, 255, 0.8);
$white-9 :rgba(255, 255, 255, 0.9);
$white-05:rgba(255, 255, 255, 0.05);
$white-08:rgba(255, 255, 255, 0.08);
$white-75:rgba(255, 255, 255, 0.075);

/*black variables*/
$black-1:rgba(0, 0, 0, 0.1);
$black-2:rgba(0, 0, 0, 0.2);
$black-3:rgba(0, 0, 0, 0.3);
$black-4:rgba(0, 0, 0, 0.4);
$black-5:rgba(0, 0, 0, 0.5);
$black-6:rgba(0, 0, 0, 0.6);
$black-7:rgba(0, 0, 0, 0.7);
$black-8:rgba(0, 0, 0, 0.8);
$black-9:rgba(0, 0, 0, 0.9);
$black-05:rgba(0, 0, 0, 0.05);


/*color1*/
$primary1:#8667ff;
$primary1-hover:#7c5df5;
$primary1-transparent:rgba(134, 103, 255, 0.13);


/*color2*/
$primary2:#824bab;
$primary2-hover:#7842a0;
$primary2-transparent:rgba(134, 103, 255, 0.13);


/*color3*/
$primary3:#1b9783;
$primary3-hover:#14806e;
$primary3-transparent:rgba(27, 151, 131, 0.13);


/*color4*/
$primary4:#4680ff;
$primary4-hover:#3a75f7;
$primary4-transparent:rgba(70, 128, 255, 0.12);

/*color5*/
$primary5:#e65145;
$primary5-hover:#da4539;
$primary5-transparent:rgba(230, 81, 69, 0.13);

/*Dark Theme*/
$dark-theme:var(--dark-theme);
$dark-body:var(--dark-body);
$dark-color:var(--dark-color);
$dark-border:var(--dark-border);
/*Dark Theme*/
